import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/global/Layout";
import AboutUs from "../components/sections/AboutUs";
import SectionParagraph from "../components/ui/SectionParagraph";
import SectionTitle from "../components/ui/SectionTitle";

const AboutUsPage = () => {
  return (
    <Layout
      titleTemplate="%s"
      pageTitle="Siler - informacje o firmie"
      pageDescription="Firma Siler prowadzi działalność od 1995roku. Specjalizujemy się między innymi w złomowaniu elektroniki, odpadów metali kolorowych, stali, odpadów katalizatorów, kabli"
    >
      <AboutUs />
      <section className="container mx-auto px-5 pb-16">
        <div className="flex flex-row gap-6 justify-between items-center flex-wrap lg:flex-nowrap space-x-8">
          <div className="md:w-1/2">
            <StaticImage src="../images/banner2.jpg" alt="O nas" />
          </div>
          <div className="md:w-1/2">
            <SectionTitle>O nas</SectionTitle>
            <SectionParagraph className="mb-5">
              Głównymi wartościami, które oferujemy naszym klientom jest uczciwa
              klasyfikacja przyjmowanego złomu i natychmiastowa płatność.
              Współpracujemy z klientami indywidualnymi oraz firmami.
            </SectionParagraph>
            <SectionParagraph className="mb-5">
              Specjalizujemy się w:
            </SectionParagraph>
            <ul className="list-disc list-inside text-xl text-gray-700">
              <li>złomowaniu elektroniki</li>
              <li>złomowaniu odpadów metali kolorowych</li>
              <li>złomowaniu stali</li>
              <li>złomowaniu odpadów katalizatorów</li>
              <li>złomowaniu kabli</li>
              <li>złomowaniu odpadów węglika spiekanego</li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutUsPage;
